<template>
	<div class="app-container-common">
		<topnav ref="topHeaderRef" :topNavActive="1" :secNavActive="2" :format="format"></topnav>
		<div class="app-container-warp">
			<videoparameter :format="format" @userHandleCall="userHandleCallFunc" types="video_compress" :idKey="idKey"></videoparameter>
		</div>
		<step :way="1"></step>
		<bottom :linkShow="true"></bottom>
	</div>
</template>

<script>
	import topnav from "../../components/topnav.vue";
	import videoparameter from "../../components/videoparameter.vue";
	import step from "@/components/step.vue";
	import bottom from '../../components/bottom.vue';
	export default {
		name: 'App',
		components: {
			topnav,videoparameter,bottom,step
		},
		created() {
			const id_key = this.$baseFunc.getLocationBarKey("key")
			if(id_key)this.idKey = id_key
		},
		data(){
			return{
				format:format,
				idKey:"",
			}
		},
		methods:{
			userHandleCallFunc(res){
				if(!res && typeof res.code !='undefined')return false;
				this.$refs.topHeaderRef.callUserHandleFunc(res.code,res.message,res)
			}
		}
	}
</script>

<style scoped>
	
</style>
